//
//
//
//
//
//
//

$(document).ready(function () {
    window.onload = function () {
        document.getElementById('downloadLink').click();
    }
});
